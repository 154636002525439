import * as React from "react"
import { makeStyles } from "@material-ui/core"
import SkillsSliderItem from "./skill-slider-item"
import Carousel from "react-multi-carousel"
import "react-multi-carousel/lib/styles.css"
import HeadsetMicIcon from '@material-ui/icons/HeadsetMic';
import SearchIcon from '@material-ui/icons/Search';
import NewReleasesIcon from '@material-ui/icons/NewReleases';
import FlashOnIcon from '@material-ui/icons/FlashOn';
import GroupIcon from '@material-ui/icons/Group';

const useStyles = makeStyles((theme) => ({
  divSlider: {
    backgroundImage: "linear-gradient(to right, #00ACC8 , #00BC74)",
    height: "auto",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-evenly",
    padding: 10,
    [theme.breakpoints.down(1100)]: {
      flexWrap: "wrap"
    }
  },
  carousel: {
    width: "100%",
    //maxWidth: 1200,
    margin: 'auto'
  }
}))

const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 5
  },
  between: {
    breakpoint: { max: 3000, min: 1600 },
    items: 4
  },
  desktop: {
    breakpoint: { max: 1600, min: 1024 },
    items: 3
  },
  tablet: {
    breakpoint: { max: 1024, min: 700 },
    items: 2
  },
  mobile: {
    breakpoint: { max: 700, min: 0 },
    items: 1
  }
}

const SkillsSlider = () => {
  const classes = useStyles()
  return <div className={classes.divSlider}>
    <Carousel responsive={responsive}
              className={classes.carousel}
              arrows={false}
              autoPlay={true}
              autoPlaySpeed={3500}
              infinite={true}
    >
      <SkillsSliderItem title={"SEO optimalizációs tapasztalatok"} icon={<SearchIcon/>} />
      <SkillsSliderItem title={"Modern megoldások"} icon={<NewReleasesIcon/>} />
      <SkillsSliderItem title={"Reszponzív, gyors felületek építése"} icon={<FlashOnIcon/>} />
      <SkillsSliderItem title={"Tapasztalt fejlesztők"} icon={<GroupIcon/>} />
      <SkillsSliderItem title={"Barátságos ügyfélszolgálat"} icon={<HeadsetMicIcon/>} />
      <SkillsSliderItem title={"Terméktervezési sprint"} icon={<SearchIcon/>} />
      <SkillsSliderItem title={"Költség tervezés"} icon={<NewReleasesIcon/>} />
      <SkillsSliderItem title={"UX ellenőrzés"} icon={<FlashOnIcon/>} />
      <SkillsSliderItem title={"Kutatás & fejlesztés"} icon={<GroupIcon/>} />
    </Carousel>
  </div>
}

export default SkillsSlider
