import * as React from "react"
import { Box, makeStyles } from "@material-ui/core"

export interface TwoColProps {
  backgroundColor?: string;
  backdropFilter?: string;
  leftColContent: React.ReactNode;
  rightColContent: React.ReactNode;
  reverseWrap?: boolean;
}

const useStyles = makeStyles((theme) => ({
  boxAll: {
    backgroundColor: "transparent",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
    padding: "44px",
    borderRadius: "25px",
  },
  wrap: {
    [theme.breakpoints.down(850)]: {
      flexWrap: 'wrap'
    }
  },
  reverseWrap: {
    [theme.breakpoints.down(850)]: {
      flexWrap: 'wrap-reverse'
    }
  },
  content: {
    width: "48%",
    [theme.breakpoints.down(850)]: {
      width: "100%",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      padding: '10px 0px'
    }
  }
}))

const TwoColumnInfo = (props: TwoColProps) => {
  const classes = useStyles()
  return <Box className={`${classes.boxAll} ${props?.reverseWrap ? classes.reverseWrap : classes.wrap}`} style={{
    backgroundColor: props?.backgroundColor,
    backdropFilter: props?.backdropFilter
  }}>
    <Box className={classes.content}>
      {props.leftColContent}
    </Box>
    <Box className={classes.content}>
      {props.rightColContent}
    </Box>
  </Box>
}

export default TwoColumnInfo
