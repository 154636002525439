import * as React from "react"
import { Box, makeStyles } from "@material-ui/core"

export interface TwoColTitleProps {
  firstPart: string;
  secondPart: string;
  isSecondPartPrimaryColor?: boolean;
  rightAlign?: boolean;
  color?: string;
}

const useStyles = makeStyles((theme) => ({
  primary: {
    color: theme.palette.primary.main,
    fontWeight: 500,
    fontSize: '36px'
  },
  base: {
    fontWeight: 500,
    fontSize: '36px',
  },
  titleLeft: {
    textAlign: 'left',
    [theme.breakpoints.down(860)]: {
      textAlign: "center"
    },
  },
  titleRight: {
    textAlign: 'right',
    [theme.breakpoints.down(860)]: {
      textAlign: "center"
    },
  },
}));

const TwoColumnTitle = (props: TwoColTitleProps) => {
  const classes = useStyles();
  const baseColor = props?.color || '#000';
  return <Box style={{
    color: baseColor,
  }} className={props?.rightAlign ? classes.titleRight : classes.titleLeft}>
    <span className={props?.isSecondPartPrimaryColor ? classes.base : classes.primary}>{props.firstPart}</span>
    <span className={props?.isSecondPartPrimaryColor ? classes.primary : classes.base}>{props.secondPart}</span>
  </Box>
}

export default TwoColumnTitle
