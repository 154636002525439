import * as React from "react"
import { Box, makeStyles } from "@material-ui/core"

export interface TwoColTextProps {
  content: React.ReactNode;
  color?: string
}

const useStyles = makeStyles((theme) => ({
  boxAll: {
    fontSize: '18px',
  }
}));

const TwoColumnText = (props: TwoColTextProps) => {
  const classes = useStyles();
  const color = props?.color || '#000';
  return <Box className={classes.boxAll} style={{color: color}}>
    {props.content}
  </Box>
}

export default TwoColumnText
