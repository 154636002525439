import * as React from "react"
import { makeStyles } from "@material-ui/core"
// import MaterialIcon, {colorPalette} from 'material-icons-react';

const useStyles = makeStyles((theme) => ({
  divIconAndText: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    background: 'rgba(255, 255, 255, 0.2)',
    color: 'white',
    margin: 15,
    paddingLeft: 20,
    paddingRight: 20,
    borderRadius: 50,
    fontSize: 14,
    height: 50
  }
}));

const SkillsSliderItem = ({title, icon}) => {
  const classes = useStyles();
  return <div className={classes.divIconAndText}>
    {icon}
      <div style={{marginLeft: 10}}>{title}</div>
  </div>
}

export default SkillsSliderItem
